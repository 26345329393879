import axios from "axios";
import { store } from "../../../state-management/state-mgmt";
import Utility from "../../../shared/utility";
export default {
  fulfilmentHoldRelease(call, obj, showMessage) {
    return this.getData("/sh/shipping_fulfillment_hold_release", call, obj, showMessage);
  },
  shippingPullList(call, obj, showMessage) {
    return this.getData("/sh/shipping_pullsheet_list", call, obj, showMessage);
  },
  shippingPullPrompt(call, userId) {
    return this.getData(`sh/shipping_pullsheet_prompt?user_id=${userId}`, call);
  },
  shippingWaybillChange(call, obj, showMessage) {
    return this.getData("/sh/shipping_waybill_change", call, obj, showMessage);
  },
  shippingAuditQueue(call, obj, showMessage) {
    return this.getData("/sh/shipping_audit_queue", call, obj, showMessage);
  },
  getProjectList(call, userId) {
    return this.getData(`sh/shipping_audit_projectlist?user_id=${userId}`, call);
  },
  getAdditionalValidations(call, userId, auditID) {
    return this.getData(`sh/shipping_audit_box_foraudit?user_id=${userId}&audit_box_id=${auditID}`, call);
  },
  // Shipping fulfillment
  getSearchShipFulData(call, obj) {
    return this.getData("/sh/shipping_fulfillment", call, obj);
  },
  getSoAddress(call, obj) {
    return this.getData("/sh/ship_so_update", call, obj);
  },
  getPoAddress(call, obj, showMessage) {
    return this.getData("/sh/ship_po_update", call, obj, showMessage);
  },
  postSOShip(call, obj) {
    return this.getData("/sh/shipping_so_lpn", call, obj);
  },
  postPOShip(call, obj) {
    return this.getData("/sh/shipping_po_lpn", call, obj);
  },
  postShippingQueue(call, obj) {
    return this.getData("/sh/shipping_queue", call, obj);
  },
  postShippingQueueFilter(call, obj, showMessage) {
    return this.getData("/sh/shipping_queue_save_filters", call, obj, showMessage);
  },
  postSearchShip(call, obj) {
    return this.getData("/sh/ship_queue_search", call, obj);
  },
  postAdvSearch(call, obj) {
    return this.getData("/sh/ship_queue_adv_search", call, obj);
  },
  //Verify Dims
  shippingInitiate(call, obj) {
    return this.getData("/sh/shipping_initiate", call, obj);
  },
  //Shipping Details
  shippingDetails(call, obj, showMessage) {
    return this.getData("/sh/shipping_details", call, obj, showMessage);
  },
  //Shipping LPN Flow
  shippingLPNFlow(call, obj, showMessage) {
    return this.getData("/sh/shipping_lpn", call, obj, showMessage);
  },
  //Shipping Non LPN Flow
  shippingNonLPNFlow(call, obj, showMessage) {
    return this.getData("/sh/shipping_non_lpn", call, obj, showMessage);
  },
  generatePullSheetFilters(call, obj) {
    return this.getData("/sh/pullsheet_dropdowns", call, obj);
  },
  onSearchPullSheet(call, obj) {
    return this.getData("/sh/shipping_pullsheet_quick_search", call, obj);
  },
  onFilterPullSheet(call, obj) {
    return this.getData("/sh/shipping_pullsheet_filter_search", call, obj);
  },
  //Bulk LPN
  shippingBulkLPN(call, obj, showMessage) {
    return this.getData("/sh/shipping_bulk_lpn", call, obj, showMessage);
  },
  shippingGenericLabel(call, obj, showMessage) {
    return this.getData("/sh/shipping_generic_label", call, obj, showMessage);
  },
  shippingPackSlip(call, obj, showMessage) {
    return this.getData("/sh/shipping_packslip", call, obj, showMessage);
  },
  shippingPackSlipReport(call, obj, showMessage) {
    return this.getData("/sh/shipping_packslip_report", call, obj, showMessage);
  },
  getPickingQueueDrop(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_dropdown", call, obj, showMessage);
  },
  pickingQueueSearch(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_filter_search", call, obj, showMessage);
  },
  pickOrderLoad(call, obj, showMessage) {
    return this.getData("/sh/pick_order_load", call, obj, showMessage);
  },
  pickQuickSearch(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_quick_search", call, obj, showMessage);
  },
  pickExitLineSearch(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_exit_line", call, obj, showMessage);
  },
  pickQueueFindPart(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_find_part", call, obj, showMessage);
  },
  pickQueueDropOffCheck(call, userId) {
    return this.getData(`sh/pick_queue_dropoff_check?user_key=${userId}`, call);
  },
  pickQueueDropOffLPN(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_dropoff_lpn", call, obj, showMessage);
  },
  userPickedLineData(call, userId) {
    return this.getData(`sh/pick_queue_load_picked_line?user_id=${userId}`, call);
  },
  pickQueuePartSearch(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_part_search", call, obj, showMessage);
  },
  pickQueueShortPick(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_short_pick", call, obj, showMessage);
  },
  pickQueueLPNValidate(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_validate_lpn", call, obj, showMessage);
  },
  pickQueueUnitIDValidate(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_validate_unit", call, obj, showMessage);
  },
  pickQueueSourceBinLPNValidate(call, obj, showMessage) {
    return this.getData("/sh/pick_queue_validate_source_bin", call, obj, showMessage);
  },

  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res !== undefined) {
      if (res.data.body.message != undefined || res.data.body.message != null) {
        if (!res?.data?.body?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.body.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.body.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;

          if (this.isJson(res?.data?.body?.message)) return JSON.parse(res?.data?.body?.message);
          else return res?.data?.body?.message;
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      } else if (res.data.body.message == null) {
        if (res.data.body !== null) {
          LoaderDialog.visible = false;
          return res?.data?.body;
        } else {
          LoaderDialog.visible = false;
          return JSON.parse('{"message":"NA"}');
        }
      } else {
        if (!res?.data?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          return JSON.parse(res?.data?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      }
    } else {
      LoaderDialog.visible = false;
      return JSON.parse('{"message":"NA"}');
    }
  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
};
