import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import FilterComp from "../../common/filter-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "./shipping-service";
import html2pdf from "html2pdf.js";
//import commonApiService from "../../common/js/common-api-service";
//import partService from "../../parts-editor/js/parts-service";
import BackToTop from "../../common/back-to-top.vue";
import PrinterDetails from "../../common/printer-details";
// import * as JSPM from "jsprintmanager";
export default {
  name: "Generate Pull Sheet",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      labelPrinter:
        EncryptUtility.localStorageDecrypt("primaryName") == "null" || EncryptUtility.localStorageDecrypt("primaryName") == undefined ? true : false,
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      defaultName: EncryptUtility.localStorageDecrypt("defaultName"),
      disablePDFPrint: false,
      actionList: [],
      arrayData: [],
      favouriteList: [],
      totalRecords: 0,
      mainPage: "",
      searchFilter: "",
      selectedProcess: "",
      selectedProject: "",
      selectedBusiness: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      soSearch: "",
      poSearch: "",
      disableProject: true,
      disableBusiness: true,
      disableOrderType: true,
      disableCarrier: true,
      showFilter: false,
      disableCountry: true,
      disableWarehouse: true,
      disablePartNumber: true,
      disableRun: true,
      warehouseList: [],
      pullSheetDataTemp: [],
      pullSheetTemp: [],
      uniqueCarrier: [],
      uniqueOtype: [],
      uniqueBusiness: [],
      uniqueStatus: [],
      processsTypeList: [],
      projectList: [],
      projectIds: [],
      businessList: [],
      businessIds: [],
      orderTypeList: [],
      orderTypes: [],
      carrierList: [],
      carrierIds: [],
      countryList: [],
      countryLists: [],
      warehouseIds: [],
      partNumList: [],
      partNumIds: [],
      pullSheetData: [],
      showPrimary: false,
      pullSheetHeader: [
        {
          text: "Type",
          value: "Type",
          class: "primary customwhite--text",
        },
        {
          text: "Order Number",
          value: "OrderNo",
          class: "primary customwhite--text",
        },
        {
          text: "Order Type",
          value: "Order_Type",
          class: "primary customwhite--text",
        },
        {
          text: "Company",
          value: "Company",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Date",
          value: "OrderDate",
          class: "primary customwhite--text",
        },
        {
          text: "Request Date",
          value: "ReqDate",
          class: "primary customwhite--text",
        },
        {
          text: "Carrier",
          value: "SLevel",
          class: "primary customwhite--text",
        },
        {
          text: "Alloc",
          value: "Alloc",
          class: "primary customwhite--text",
        },
        {
          text: "Status",
          value: "Status",
          class: "primary customwhite--text",
        },
        {
          text: "Note",
          value: "Note",
          class: "primary customwhite--text",
        },
        {
          text: "View",
          value: "View",
          class: "primary customwhite--text",
        },
      ],
      selectedOrders: [],
      orderFilterJSON: {
        process_list: [],
        projkey_list: [],
        bukey_list: [],
        otype_list: [],
        slkey_list: [],
        country_list: [],
        warekey_list: [],
        part_number: [],
      },
      pullSheetLink: "",
      pullSheetDialog: false,
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    if (EncryptUtility.localStorageDecrypt("pullSheetQuickSearch")) {
      let obj = JSON.parse(EncryptUtility.localStorageDecrypt("pullSheetQuickSearch"));
      if (obj?.ordno) {
        this.showFilter = false;
        if (obj.type == "SO") {
          this.soSearch = obj?.ordno;
          let resData = await shippingService.onSearchPullSheet("post", obj);
          this.pullSheetData = resData;
          this.totalRecords = resData?.length;
        } else {
          this.poSearch = obj?.ordno;
          let resData = await shippingService.onSearchPullSheet("post", obj);
          this.pullSheetData = resData;
          this.totalRecords = resData?.length;
        }
      }
    }
    if (EncryptUtility.localStorageDecrypt("filterObjects")) {
      let storedArray = JSON.parse(EncryptUtility.localStorageDecrypt("filterObjects"));
      this.arrayData = storedArray;
    }
    if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
      this.disablePDFPrint = false;
    }
  },
  async mounted() {
    let processObj = {
      UserID: this.userId,
      ddl_pass: 1,
      processType: "",
      projkey_list: "",
      bukey_list: "",
      otype_list: "",
      slkey_list: "",
      country_list: "",
      warekey_list: "",
    };
    let processType = await shippingService.generatePullSheetFilters("post", processObj);
    this.processsTypeList = processType;
    if (EncryptUtility.localStorageDecrypt("pullSheetFilterSearch")) {
      let obj = JSON.parse(EncryptUtility.localStorageDecrypt("pullSheetFilterSearch"));
      let ObjectJson = JSON.parse(EncryptUtility.localStorageDecrypt("pullSheetFilterSearch")).json;
      ObjectJson = JSON.parse(ObjectJson);
      this.showFilter = true;
      this.selectedProcess = ObjectJson?.process_list[0];
      this.selectedProject = ObjectJson?.projkey_list[0];
      this.selectedBusiness = ObjectJson?.bukey_list[0];
      this.projectAPI(this.selectedProcess), this.businessAPI(this.selectedProject), this.orderTypeAPI(this.selectedBusiness);
      this.disableProject = false;
      this.disableBusiness = false;
      this.disableOrderType = false;
      this.disableCarrier = false;
      this.disableCountry = false;
      this.disableWarehouse = false;
      this.disablePartNumber = false;
      this.disableRun = false;
      let resData = await shippingService.onFilterPullSheet("post", obj);
      if (resData?.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Result Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.totalRecords = 0;
        this.pullSheetData = [];
      } else {
        const newArr = resData.map((obj) => {
          return {
            ...obj,
            OrderDate: Utility.convertESTToLocal(obj.OrderDate),
          };
        });
        this.pullSheetData = newArr;
        this.pullSheetTemp = newArr;
      }
      this.totalRecords = resData?.length;
    }
  },
  computed: {
    frameSrc() {
      return `data:application/pdf;base64,${this.pullSheetLink}#toolbar=0`;
    },
  },
  methods: {
    printDetails() {
      if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
        let poId = [],
          soId = [];
        if (this.selectedOrders?.length > 0) {
          this.selectedOrders.forEach((element) => {
            if (element?.so_id > 0) {
              soId.push(element?.so_id);
            }
            if (element?.po_id > 0) {
              poId.push(element?.po_id);
            }
          });
          if (soId) {
            soId = soId?.join(",");
          }
          if (poId) {
            poId = poId?.join(",");
          }
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let obj = {
            user_id: parseInt(this.userId),
            po_id: poId,
            so_id: soId,
            purno: "",
            sono: "",
          };
          this.axios
            .post("sh/shipping_pullsheet_report", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                this.pdfName = "Order_Details_" + ".pdf";
                let responseData = response.data.body.message;
                //Default Printer is consumed to print the PDF
                Utility.printZPLPDF(responseData, this.defaultName);
                //           this.selectedOrders = [];
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select any Order Number to Print",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Default Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    selectAllToggle(props) {
      if (this.selectedOrders.length == 0) {
        this.selectedOrders = [];
        const self = this;
        props.items.forEach((item) => {
          if (item.Alloc == "Y") {
            self.selectedOrders.push(item);
          }
        });
      } else {
        this.selectedOrders = [];
      }
    },
    async projectAPI(data) {
      this.orderFilterJSON.process_list = [];
      this.orderFilterJSON.process_list.push(data);
      let projectObj = {
        UserID: this.userId,
        ddl_pass: 2,
        process_list: JSON.stringify(this.orderFilterJSON.process_list),
        projkey_list: "",
        bukey_list: "",
        otype_list: "",
        slkey_list: "",
        country_list: "",
        warekey_list: "",
      };
      let projectData = await shippingService.generatePullSheetFilters("post", projectObj);
      this.projectList = projectData;
    },
    async businessAPI(data) {
      this.orderFilterJSON.bukey_list = [];
      this.orderFilterJSON.bukey_list.push(data);
      let businessObj = {
        UserID: this.userId,
        ddl_pass: 3,
        process_list: JSON.stringify(this.orderFilterJSON.process_list),
        projkey_list: JSON.stringify(this.orderFilterJSON.bukey_list),
        bukey_list: "",
        otype_list: "",
        slkey_list: "",
        country_list: "",
        warekey_list: "",
      };
      let businessData = await shippingService.generatePullSheetFilters("post", businessObj);
      this.businessList = businessData;
    },
    async orderTypeAPI(data) {
      this.orderFilterJSON.otype_list = [];
      this.orderFilterJSON.otype_list.push(data);
      let orderTypeObj = {
        UserID: this.userId,
        ddl_pass: 4,
        process_list: JSON.stringify(this.orderFilterJSON.process_list),
        projkey_list: JSON.stringify(this.orderFilterJSON.projkey_list),
        bukey_list: JSON.stringify(this.orderFilterJSON.otype_list),
        otype_list: "",
        slkey_list: "",
        country_list: "",
        warekey_list: "",
      };
      let orderTypeData = await shippingService.generatePullSheetFilters("post", orderTypeObj);
      this.orderTypeList = orderTypeData;
    },
    onChangeProcess(item) {
      this.disableProject = false;
      this.projectAPI(item);
      this.disableBusiness = true;
      this.disableOrderType = true;
      this.disableCarrier = true;
      this.disableCountry = true;
      this.disableWarehouse = true;
      this.disablePartNumber = true;
      this.orderFilterJSON.projkey_list = [];
      this.orderFilterJSON.bukey_list = [];
      this.orderFilterJSON.otype_list = [];
      this.orderFilterJSON.slkey_list = [];
      this.orderFilterJSON.country_list = [];
      this.orderFilterJSON.warekey_list = [];
      this.pullSheetData = [];
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.disableRun = true;
      this.showFilter = false;
      this.totalRecords = 0;
    },
    async onChangeProject(project) {
      this.disableBusiness = false;
      this.businessAPI(project);
      this.disableOrderType = true;
      this.disableCarrier = true;
      this.disableCountry = true;
      this.disableWarehouse = true;
      this.disablePartNumber = true;
      this.orderFilterJSON.bukey_list = [];
      this.pullSheetData = [];
      this.showFilter = false;
      this.totalRecords = 0;
      this.orderFilterJSON.otype_list = [];
      this.orderFilterJSON.slkey_list = [];
      this.orderFilterJSON.country_list = [];
      this.orderFilterJSON.warekey_list = [];
      this.selectedBusiness = "";
      this.disableRun = true;
    },
    async onChangeBusiness(business) {
      this.disableRun = false;
      this.disableOrderType = false;
      this.orderTypeAPI(business);
      this.disableCarrier = true;
      this.disableCountry = true;
      this.disableWarehouse = true;
      this.disablePartNumber = true;
      this.pullSheetData = [];
      this.orderFilterJSON.otype_list = [];
      this.orderFilterJSON.slkey_list = [];
      this.orderFilterJSON.country_list = [];
      this.orderFilterJSON.warekey_list = [];
      this.showFilter = false;
      this.totalRecords = 0;
    },
    async onClickRun() {
      this.selectedOrders = [];
      this.orderFilterJSON.process_list = [];
      this.orderFilterJSON.projkey_list = [];
      this.orderFilterJSON.bukey_list = [];
      this.orderFilterJSON.process_list.push(this.selectedProcess);
      this.orderFilterJSON.projkey_list.push(this.selectedProject);
      this.orderFilterJSON.bukey_list.push(this.selectedBusiness);
      let submitObj = {
        userId: this.userId,
        json: JSON.stringify({
          process_list: this.orderFilterJSON.process_list,
          projkey_list: this.orderFilterJSON.projkey_list,
          bukey_list: this.orderFilterJSON.bukey_list,
          otype_list: [],
          slkey_list: [],
          country_list: [],
          warekey_list: [],
          partnum_list: [],
        }),
      };
      let resData = await shippingService.onFilterPullSheet("post", submitObj);
      if (resData.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Result Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.totalRecords = 0;
        this.pullSheetData = [];
      } else {
        this.showFilter = true;
        const newArr = resData.map((obj) => {
          return {
            ...obj,
            OrderDate: Utility.convertESTToLocal(obj.OrderDate),
          };
        });
        this.pullSheetData = newArr;
        this.pullSheetTemp = newArr;
        this.totalRecords = resData?.length;
        let tempObj = JSON.parse(JSON.stringify(submitObj));
        EncryptUtility.localStorageEncrypt("pullSheetFilterSearch", JSON.stringify(tempObj));
        this.uniqueStatus = [...new Map(this.pullSheetTemp.map((item) => [item["Status"], item.Status])).values()];
        this.uniqueCarrier = [...new Map(this.pullSheetTemp.map((item) => [item["SLevel"], item.SLevel])).values()];
        this.uniqueOtype = [...new Map(this.pullSheetTemp.map((item) => [item["Order_Type"], item.Order_Type])).values()];
        this.arrayData = [
          { id: 1, label: "Carrier", array: this.uniqueCarrier },
          { id: 2, label: "Status", array: this.uniqueStatus },
          { id: 3, label: "Order Type", array: this.uniqueOtype },
        ];
        EncryptUtility.localStorageEncrypt("filterObjects", JSON.stringify(this.arrayData));
        this.$vuetify.goTo(0);
        EncryptUtility.localStorageEncrypt("pullSheetQuickSearch", "");
      }
    },
    clearFilter() {
      this.arrayData = [];
      this.selectedProcess = "";
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.disableProject = true;
      this.disableBusiness = true;
      this.disableOrderType = true;
      this.disableCarrier = true;
      this.disableRun = true;
      this.disableCountry = true;
      this.disableWarehouse = true;
      this.disablePartNumber = true;
      this.projectIds = [];
      this.businessIds = [];
      this.orderTypes = [];
      this.carrierList = [];
      this.carrierIds = [];
      this.countryLists = [];
      this.warehouseIds = [];
      this.partNumIds = [];
      this.orderFilterJSON.process_list = [];
      this.orderFilterJSON.projkey_list = [];
      this.orderFilterJSON.bukey_list = [];
      this.orderFilterJSON.otype_list = [];
      this.orderFilterJSON.slkey_list = [];
      this.orderFilterJSON.country_list = [];
      this.orderFilterJSON.warekey_list = [];
      this.orderFilterJSON.part_number = [];
      EncryptUtility.localStorageEncrypt("pullSheetQuickSearch", "");
      EncryptUtility.localStorageEncrypt("filterObjects", "");
      EncryptUtility.localStorageEncrypt("pullSheetFilterSearch", "");
      this.pullSheetData = [];
      this.showFilter = false;
      this.totalRecords = 0;
    },
    async onClickSearch() {
      this.selectedOrders = [];
      this.showFilter = false;
      let searchObj = {
        user_id: this.userId,
        type: this.poSearch ? "PO" : "SO",
        ordno: this.poSearch ? this.poSearch : this.soSearch,
      };
      let resData = await shippingService.onSearchPullSheet("post", searchObj);
      if (resData?.message == "NA" || !resData) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Result Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.totalRecords = 0;
        this.pullSheetData = [];
        EncryptUtility.localStorageEncrypt("pullSheetQuickSearch", "");
      } else {
        this.pullSheetData = resData;
        this.totalRecords = resData?.length;
        EncryptUtility.localStorageEncrypt("pullSheetQuickSearch", JSON.stringify(searchObj));
        EncryptUtility.localStorageEncrypt("pullSheetFilterSearch", "");
      }
    },
    async filteredValues(value, type) {
      const filters = {
        0: () => this.pullSheetTemp,
        1: (value) => this.pullSheetTemp.filter((element) => element.SLevel === value),
        2: (value) => this.pullSheetTemp.filter((element) => element.Status === value),
        3: (value) => this.pullSheetTemp.filter((element) => element.Order_Type === (value === "< BLANK >" ? "" : value)),
      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.pullSheetData = data;
    },
    //Pull sheet details
    pullSheetDetails(item) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        user_id: parseInt(this.userId),
        po_id: item.Type == "PO" ? item.po_id : 0,
        so_id: item.Type == "SO" ? item.so_id : 0,
        purno: "",
        sono: "",
      };
      this.axios
        .post("sh/shipping_pullsheet_report", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = response.data.body.message;
            this.pullSheetLink = "";
            this.pullSheetLink = responseData;
            this.pullSheetDialog = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.pullSheetDialog = false;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Printing the Pull sheet
    printPullSheet() {
      if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
        Utility.printZPLPDF(this.pullSheetLink, this.defaultName);
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Default Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    resetFunction() {
      this.soSearch = "";
      this.poSearch = "";
      this.clearFilter();
      this.showFilter = false;
      EncryptUtility.localStorageEncrypt("pullSheetQuickSearch", "");
      EncryptUtility.localStorageEncrypt("pullSheetFilterSearch", "");
    },
  },
  components: {
    breadcrumbComp,
    html2pdf,
    BackToTop,
    PrinterDetails,
    FilterComp,
  },
};
